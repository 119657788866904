export default {
  selectors: {
    menuButton: '.batcom-menuButton__base',
    search: '.batcom-search',
    searchToggle: '.cmp-search__toggle',
    searchMenu: '.cmp-search__menu',
    header: '.batcom-header',
    headerWrapper: '.batcom-header__wrapper',
    navigation: '.batcom-navigation',
    headerLinks: '.batcom-header__links',
    clearButton: '.cmp-search__clear',
    navigationMenu: '.cmp-navigation',
    languageNavigationMenu: '.cmp-languagenavigation',
  },
  classes: {
    noScroll: 'noScroll',
    open: 'open',
    visible: 'visible',
    hidden: 'hidden',
    headerDefault: 'batcom-header-default',
    headerVar1: 'batcom-header-var-1',
    headerVar2: 'batcom-header-var-2',
    headerVar3: 'batcom-header-var-3',
    burgerMenuActive: 'batcom-burgerMenu--active',
  },
};
