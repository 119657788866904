import { register } from '@netcentric/component-loader';
import config from './batcom-searchheader.config';
import { isOnDesktop, isOnMobileOrTablet, removeBodyScroll, addBodyScroll } from '../../../commons/utils';
import * as Events from '../../../commons/constants/events';

class SearchHeader {
  constructor(el) {
    this.el = el;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.init();
  }

  init() {
    this.setRefs();
    // SearchHeader component using Search component
    // We use publish/subscribe pattern (window.PubSub) to communicate
    this.setPubSubListeners();
    this.showSearchHeader();
  }

  setRefs() {
    this.body = document.body;
    this.menuButton = document.querySelector(this.selectors.menuButton);
    this.searchToggle = this.el.querySelector(this.selectors.searchToggle); // loop icon
    this.searchMenu = this.el.querySelector(this.selectors.searchMenu);
    this.clearButton = this.el.querySelector(this.selectors.clearButton);
    this.header = this.el.closest(this.selectors.header);
    this.headerWrapper = this.header.querySelector(this.selectors.headerWrapper);
    this.navigation = this.header.querySelector(this.selectors.navigation);
    this.headerLinks = this.header.querySelector(this.selectors.headerLinks);
    this.navigationMenu = document.querySelector(this.selectors.navigationMenu);
    this.languageNavigationMenu = document.querySelector(this.selectors.languageNavigationMenu);
  }

  setPubSubListeners() {
    window.PubSub.subscribe(Events.OPEN_SEARCH, () => {
      this.openSearch();
    });

    window.PubSub.subscribe(Events.CLOSE_SEARCH, () => {
      this.closeSearch();
    });
  }

  showSearchHeader() {
    this.el.classList.add(this.classes.visible);
  }

  openSearch() {
    this.toggleSearchHeader();

    if (this.languageNavigationMenu?.classList.contains(this.classes.open)) {
      window.PubSub.publish(Events.CLOSE_LANGUAGE_NAVIGATION);
    }

    if (this.navigationMenu?.classList.contains(this.classes.visible)) {
      window.PubSub.publish(Events.CLOSE_NAVIGATION);
    }

    if (isOnDesktop()) {
      this.handleSearchExpansion();
    }
  }

  toggleSearchHeader() {
    if (this.searchMenu?.classList.contains(this.classes.open)) {
      this.searchMenu.classList.remove(this.classes.open);
      this.clearButton.classList.remove(this.classes.open);
      this.clearButton.setAttribute('aria-expanded', 'false');
      if (isOnMobileOrTablet()) {
        removeBodyScroll(this.classes.noScroll);
      }
      window.PubSub.publish(Events.RESET_SEARCH);
    } else {
      if (isOnMobileOrTablet()) {
        addBodyScroll(this.classes.noScroll);
      }
      this.searchMenu.classList.add(this.classes.open);
      this.clearButton.classList.add(this.classes.open);
      this.clearButton.setAttribute('aria-expanded', 'true');
      window.PubSub.publish(Events.FOCUS_SEARCH_INPUT);
    }
  }

  closeMenu() {
    this.searchMenu.classList.remove(this.classes.open);
  }

  closeSearch() {
    const headerVar2 = this.headerWrapper.classList.contains(this.classes.headerVar2);

    window.PubSub.publish(Events.RESET_SEARCH);
    this.closeMenu();

    if (isOnDesktop() && headerVar2) {
      this.resetSearchWidth();
    }

    if (isOnDesktop()) {
      this.showElement(this.navigation);
      this.showElement(this.headerLinks);
      this.showElement(this.searchToggle);
      this.removeFlexGrow();
    }
  }

  handleSearchExpansion() {
    const headerDefault = this.headerWrapper.classList.contains(this.classes.headerDefault);
    const headerVar1 = this.headerWrapper.classList.contains(this.classes.headerVar1);
    const headerVar2 = this.headerWrapper.classList.contains(this.classes.headerVar2);
    const headerVar3 = this.headerWrapper.classList.contains(this.classes.headerVar3);
    const burgerMenuActive = this.header.classList.contains(this.classes.burgerMenuActive);

    if ((headerDefault && !burgerMenuActive) || (headerVar1 && !burgerMenuActive)) {
      this.hideElement(this.navigation);
      this.hideElement(this.searchToggle);
      this.expandSearch();
    } else if ((headerDefault && burgerMenuActive) || (headerVar1 && burgerMenuActive)) {
      this.hideElement(this.searchToggle);
      this.expandSearch();
    } else if (headerVar2) {
      this.hideElement(this.headerLinks);
      this.hideElement(this.searchToggle);
      this.expandSearch(false);
    } else if (headerVar3) {
      this.hideElement(this.headerLinks);
      this.hideElement(this.searchToggle);
      this.expandSearch();
    }
  }

  expandSearch(addFlexGrow = true) {
    if (addFlexGrow) {
      this.addFlexGrow();
    } else {
      this.setSearchWidth();
    }
    window.PubSub.publish(Events.FOCUS_SEARCH_INPUT);
  }

  showElement(el) {
    el.classList.remove(this.classes.hidden);
  }

  hideElement(el) {
    el.classList.add(this.classes.hidden);
  }

  addFlexGrow() {
    this.el.style.flexGrow = 1;
  }

  removeFlexGrow() {
    this.el.style.flexGrow = 'initial';
  }

  setSearchWidth() {
    this.el.style.width = '35%';
    this.el.style.marginLeft = 'auto';
  }

  resetSearchWidth() {
    this.el.style.width = 'auto';
    this.el.style.marginLeft = 'initial';
  }
}

register({ SearchHeader });
